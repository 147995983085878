<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>数据填报</el-breadcrumb-item>
      <el-breadcrumb-item>数据填报</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区域 -->
    <el-card>
      <!-- 施工员 待定-->
      <div class="progress" v-if="roleName == '施工员'">
        <!-- <div class="progress" v-if="arr.indexOf('progress') > -1">     -->
        <el-row>
          <el-col :span="17"><span class="title">施工员-进度表</span></el-col>
          <el-col :span="7">
            <el-input
              placeholder="请输入名称"
              v-model="proQuery.query"
              @keyup.enter="getProgress()"
              clearable
              @clear="getProgress()"
            >
              <template #append>
                <el-button
                  icon="el-icon-search"
                  @click="getProgress()"
                ></el-button>
              </template>
            </el-input>
          </el-col>
        </el-row>
        <el-table :data="progress" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column
            label="进度单编号"
            prop="progressNo"
          ></el-table-column>
          <el-table-column label="日期和时间" prop="time"></el-table-column>
          <el-table-column label="起桩号" prop="beginPile"></el-table-column>
          <el-table-column label="止桩号" prop="endPile"></el-table-column>
          <el-table-column
            label="本次完成工程量"
            prop="completeQuantity"
          ></el-table-column>
          <el-table-column
            label="本次完成金额"
            prop="completeTotality"
          ></el-table-column>
          <el-table-column
            label="累计完成工程量"
            prop="completedQuantity"
          ></el-table-column>
          <el-table-column
            label="累计完成金额"
            prop="completedTotality"
          ></el-table-column>
          <el-table-column
            label="累计完成百分比"
            prop="percent"
          ></el-table-column>
          <el-table-column label="项目编号" prop="itemNo"></el-table-column>
          <el-table-column label="项目名称" prop="itemName"></el-table-column>
          <el-table-column label="合价" prop="combinedPrice"></el-table-column>
          <el-table-column label="操作" width="300px">
            <template v-slot="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="reportPro(scope.row)"
                >填报</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination
            @size-change="proSizeChange"
            @current-change="proCurChange"
            :current-page="proQuery.pagenum"
            :page-sizes="[5, 10, 20]"
            :page-size="proQuery.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="proTotal"
          ></el-pagination>
        </el-config-provider>
        <!-- 填报对话框  -->
        <el-dialog
          title="数据填报"
          v-model="reportProVisible"
          width="50%"
          @close="reportProClosed"
        >
          <el-form
            ref="reportPro"
            :model="curPro"
            label-width="80px"
            width="100%"
          >
            <el-form-item label="进度表关联编号">
              <el-input v-model="curPro.progressContentId"></el-input>
            </el-form-item>
            <el-form-item label="进度编号">
              <el-input v-model="curPro.progressNo"></el-input>
            </el-form-item>
            <el-form-item label="关联项目编号">
              <el-input v-model="curPro.itemNo"></el-input>
            </el-form-item>
            <el-form-item label="项目名称">
              <el-input v-model="curPro.itemName"></el-input>
            </el-form-item>
            <el-form-item label="起桩号">
              <el-input v-model="curPro.beginPile"></el-input>
            </el-form-item>
            <el-form-item label="止桩号">
              <el-input v-model.number="curPro.endPile"></el-input>
            </el-form-item>
            <el-form-item label="本次完成工程量">
              <el-input v-model.number="curPro.completeQuantity"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="reportProVisible = false">取消</el-button>
              <el-button type="primary" @click="submitPro()">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- <el-input placeholder="请输入试验名称" v-model="expQuery.query" @keyup.enter="getExperimentSearch()" clearable
        @clear="getExperiment()"> </el-input>-->

      <!-- 试验员 -->
      <div class="experiment" v-if="roleName == '试验员'">
        <!-- <div class="experiment"  v-if="arr.indexOf('experiment') > -1"> -->
        <el-row>
          <el-col :span="16"
            ><span class="title">试验员-试验检测表</span></el-col
          >
          <el-col :span="8">
            <el-select
              v-model="expQuery.project"
              clearable
              placeholder="请选择工程名称"
            >
              <el-option v-for="item in projectList" :value="item"></el-option>
            </el-select>
            <el-select
              v-model="expQuery.material"
              clearable
              placeholder="请选择材料及实体名称"
            >
              <el-option v-for="item in materialList" :value="item"></el-option>
            </el-select>
            <el-button
              icon="el-icon-search"
              @click="getExperiment()"
            ></el-button>
          </el-col>
        </el-row>
        <el-table :data="experiment" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column
            label="工程名称"
            min-width="140"
            prop="projectName"
          ></el-table-column>
          <el-table-column
            label="材料及实体名称"
            min-width="120"
            prop="materialEntityName"
          ></el-table-column>
          <el-table-column
            label="检测项目"
            min-width="140"
            prop="testItem"
          ></el-table-column>
          <el-table-column
            label="检测频率"
            min-width="160"
            prop="testFrequency"
          ></el-table-column>
          <el-table-column
            label="说明"
            min-width="140"
            prop="explain"
          ></el-table-column>
          <el-table-column
            label="工程单位"
            prop="quantityUnit"
          ></el-table-column>
          <el-table-column
            label="清单工程量"
            prop="listQuantity"
          ></el-table-column>
          <el-table-column
            label="现场进度工程量"
            prop="progressQuantity"
          ></el-table-column>
          <el-table-column label="检测单位" prop="testUnit"></el-table-column>
          <el-table-column label="检测系数" prop="testRatio"></el-table-column>
          <el-table-column
            label="清单应检数量"
            prop="listRequiredQuantity"
          ></el-table-column>
          <el-table-column
            label="进度应检数量"
            prop="progressRequiredQuantity"
          ></el-table-column>
          <el-table-column
            label="实际检测数量"
            prop="actualRequiredQuantity"
          ></el-table-column>
          <el-table-column
            label="已取检测报告数量"
            prop="obtainedQuantity"
          ></el-table-column>
          <el-table-column
            label="未取检测报告数量"
            prop="notObtainedQuantity"
          ></el-table-column>
          <el-table-column
            label="检测合格数量"
            prop="passQuantity"
          ></el-table-column>
          <el-table-column
            label="检测不合格数量"
            prop="notPassQuantity"
          ></el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="reportExp(scope.row)"
                >填报</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination
            @size-change="expSizeChange"
            @current-change="expCurChange"
            :current-page="expQuery.pagenum"
            :page-sizes="[5, 10, 20]"
            :page-size="expQuery.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="expTotal"
          ></el-pagination>
        </el-config-provider>
        <!-- 填报对话框  -->
        <el-dialog
          title="数据填报"
          v-model="reportExpVisible"
          width="50%"
          @close="reportExpClosed"
        >
          <el-form
            ref="reportExp"
            :model="curExp"
            label-width="auto"
            width="100%"
          >
          <el-form-item label="试验检测项目编号">
              <el-input v-model="curExp.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="清单工程量">
              <el-input v-model="curExp.listQuantity" disabled></el-input>
            </el-form-item>
            <el-form-item label="现场进度工程量">
              <el-input v-model="curExp.progressQuantity" disabled></el-input>
            </el-form-item>
            <el-form-item label="实际检测数量">
              <el-input v-model="curExp.actualRequiredQuantity"></el-input>
            </el-form-item>
            <el-form-item label="已取检测报告数量">
              <el-input v-model="curExp.obtainedQuantity"></el-input>
            </el-form-item>
            <el-form-item label="检测合格数量">
              <el-input v-model="curExp.passQuantity"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="reportExpVisible = false">取消</el-button>
              <el-button type="primary" @click="submitExp()">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>

      <!-- 资料员 -->
      <div class="document" v-if="roleName == '资料员'">
        <!-- <div class="document"  v-if="arr.indexOf('document') > -1"> -->
        <el-row>
          <el-col :span="17"><span class="title">资料员-资料表</span></el-col>
          <el-col :span="7">
            <el-input
              placeholder="请输入资料名称"
              v-model="docQuery.query"
              @keyup.enter="getDocument()"
              clearable
              @clear="getDocument()"
            >
              <template #append>
                <el-button
                  icon="el-icon-search"
                  @click="getDocument()"
                ></el-button>
              </template>
            </el-input>
          </el-col>
        </el-row>
        <el-table
          :data="document"
          border
          stripe
          style="margin-bottom: 15px"
          show-summary
          :summary-method="computeAll"
        >
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="册数" prop="volume"></el-table-column>
          <el-table-column
            label="文件材料名称"
            prop="documentContentName"
          ></el-table-column>
          <el-table-column
            label="资料说明"
            prop="documentDescription"
          ></el-table-column>
          <el-table-column
            label="资料完成情况说明"
            prop="documentCompleteDescription"
          ></el-table-column>
          <el-table-column label="总数" prop="totalNum"></el-table-column>
          <el-table-column label="编制数" prop="organizationNum" v-slot="scope">
            {{
              scope.row.organizationNum == -1
                ? "不需要"
                : scope.row.organizationNum
            }}
          </el-table-column>
          <el-table-column
            label="监理签认数"
            prop="supervisorNum"
            v-slot="scope"
          >
            {{
              scope.row.supervisorNum == -1 ? "不需要" : scope.row.supervisorNum
            }}
          </el-table-column>
          <el-table-column label="设计签认数" prop="designNum" v-slot="scope">
            {{
              scope.row.designNum == -1 ? "不需要" : scope.row.designNum
            }}</el-table-column
          >
          <el-table-column
            label="建设单位签认数"
            prop="constructionNum"
            v-slot="scope"
          >
            {{
              scope.row.constructionNum == -1
                ? "不需要"
                : scope.row.constructionNum
            }}</el-table-column
          >
          <el-table-column
            label="存档原件数"
            prop="archiveOriginalNum"
            v-slot="scope"
          >
            {{
              scope.row.archiveOriginalNum == -1
                ? "不需要"
                : scope.row.archiveOriginalNum
            }}
          </el-table-column>
          <el-table-column
            label="存档复印数"
            prop="archiveCopyNum"
            v-slot="scope"
          >
            {{
              scope.row.archiveCopyNum == -1
                ? "不需要"
                : scope.row.archiveCopyNum
            }}
          </el-table-column>
          <el-table-column label="操作"
            ><template v-slot="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="reportDoc(scope.row)"
                >填报</el-button
              >
            </template></el-table-column
          >
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination
            @size-change="docSizeChange"
            @current-change="docCurChange"
            :current-page="docQuery.pagenum"
            :page-sizes="[5, 10, 20]"
            :page-size="docQuery.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="docTotal"
          ></el-pagination>
        </el-config-provider>
        <!-- 填报对话框  -->
        <el-dialog
          title="数据填报"
          v-model="reportDocVisible"
          width="50%"
          @close="reportDocClosed"
        >
          <el-form
            ref="reportDoc"
            :model="curDoc"
            label-width="150px"
            width="100%"
          >
            <el-form-item label="文件材料名称">
              <el-input
                v-model="curDoc.documentContentName"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="资料说明">
              <el-input
                v-model="curDoc.documentDescription"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="资料完成情况说明">
              <el-input
                v-model="curDoc.documentCompleteDescription"
                readonly
              ></el-input>
            </el-form-item>
            <el-form-item label="总数">
              <el-input v-model="curDoc.totalNum"></el-input>
            </el-form-item>
            <el-form-item label="编制数">
              <el-input v-model="curDoc.organizationNum"></el-input>
            </el-form-item>
            <el-form-item label="监理签认数">
              <el-input v-model="curDoc.supervisorNum"></el-input>
            </el-form-item>
            <el-form-item label="设计签认数">
              <el-input v-model="curDoc.designNum"></el-input>
            </el-form-item>
            <el-form-item label="建设单位签认数">
              <el-input v-model="curDoc.constructionNum"></el-input>
            </el-form-item>
            <el-form-item label="存档原件数">
              <el-input v-model="curDoc.archiveOriginalNum"></el-input>
            </el-form-item>
            <el-form-item label="存档复印数">
              <el-input v-model="curDoc.archiveCopyNum"></el-input>
            </el-form-item>
          </el-form>
          <!-- 底部 -->
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="reportDocVisible = false">取消</el-button>
              <el-button type="primary" @click="submitDoc()">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- 合约员 -->
      <div class="Contractor" v-if="roleName == '合约员'">
        <!-- <div class="document"  v-if="arr.indexOf('document') > -1"> -->
        <div v-for="(data, index) in ContractorData" :key="index">
          <el-row>
            <el-col :span="17"
              ><span class="title">{{ data.projectName }}</span></el-col
            >
          </el-row>
          <el-table
            :data="data.part_projectData"
            border
            stripe
            style="margin-bottom: 15px"
            show-summary
            :summary-method="compute"
          >
            <el-table-column
              label="工程名称"
              prop="part_project"
            ></el-table-column>
            <el-table-column label="金额" prop="total"></el-table-column>
            <el-table-column
              label="暂列金额"
              prop="temporaryTotal"
            ></el-table-column>
            <el-table-column label="操作"
              ><template v-slot="scope">
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="mini"
                  @click="ContractorReport(scope.row)"
                  >填报</el-button
                >
              </template></el-table-column
            >
          </el-table>
        </div>
        <el-dialog
          title="数据填报"
          v-model="ContractorVisible"
          width="50%"
          @close="ContractorClosed"
        >
          <el-form
            ref="ContractorDialog"
            :model="curContractor"
            label-width="150px"
            width="100%"
          >
            <el-form-item label="工程名称">
              <el-input v-model="curContractor.part_project" readonly></el-input>
            </el-form-item>
            <el-form-item label="金额">
              <el-input v-model="curContractor.total"></el-input>
            </el-form-item>
            <el-form-item label="暂列金额">
              <el-input v-model="curContractor.temporaryTotal"></el-input>
            </el-form-item>
          </el-form>
          <!-- 底部 -->
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="ContractorVisible = false">取消</el-button>
              <el-button type="primary" @click="submitContractor()"
                >确定</el-button
              >
            </span>
          </template>
        </el-dialog>
      </div>
      <div class="tatol">金额总计：{{ sumMessage }}</div>
      <div class="tatol">暂列金额总计：{{ TemporaryMessage }}</div>
    </el-card>
  </div>
</template>

<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  data() {
    return {
      // arr:['document','experiment','progress'],
      // 合约员
      ContractorQuery: {},
      curContractor: {},
      ContractorVisible: false,
      ContractorData: [],


      materialList: [],
      projectList: [],
      itemlist: [],
      curPro: {},
      progress: [],
      planList: [],
      reportProVisible: false,
      proQuery: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      planQuery: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      proTotal: 0,

      curExp: {},
      experiment: [],
      reportExpVisible: false,
      expQuery: {
        query: "",
        pagenum: 1,
        pagesize: 5,
        project: "",
        material: "",
      },
      expTotal: 0,

      curDoc: {},
      document: [],
      reportDocVisible: false,
      docQuery: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      docTotal: 0,
      docSum: {},

      roleName: "",
    };
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  computed: {
    sumMessage() {
      var sum = 0;
      var arr = this.ContractorData;
      console.log(222, this.ContractorData);
      arr.forEach((item) => {
        let array = item.part_projectData;
        array.forEach((data) => {
          sum = sum + data.total;
        });
      });
      return sum;
    },
    TemporaryMessage() {
      var sum = 0;
      var arr = this.ContractorData;
      arr.forEach((item) => {
        let array = item.part_projectData;
        console.log(array);
        array.forEach((data) => {
          console.log(data.temporaryTotal,'gg');
          sum = sum + data.temporaryTotal;
        });
      });
      return sum;
    },
  },
  mounted() {
    // 获取当前用户的【岗位】对应的【权限列表】   按钮级
    // this.arr = window.sessionStorage.getItem('permisson')
    this.roleName = window.sessionStorage.getItem("roleName");
    // 获取基础数据
    if (this.roleName == "资料员") {
      this.getDocument();
    }
    if (this.roleName == "试验员") {
      this.getExperiment();
    }
    if (this.roleName == "施工员") {
      this.getProgress();
      this.getItemList();
    }
    if (this.roleName == "合约员") {
      this.getParameterTitle();
    }
  },
  methods: {
    computeAll(arg) {
      let sum = [];
      arg.columns.forEach((res, index) => {
        // console.log(res)
        if (index != 0) {
          sum.push(" ");
          switch (index) {
            case 5:
              sum[5] = this.docSum.total;
            case 6:
              sum[6] = this.docSum.organization;
            case 7:
              sum[7] = this.docSum.supervisor;
            case 8:
              sum[8] = this.docSum.design;
            case 9:
              sum[9] = this.docSum.construction;
            case 10:
              sum[10] = this.docSum.archiveOrigin;
            case 11:
              sum[11] = this.docSum.archiveCopy;
          }
        } else {
          sum.push("合计");
        }
      });
      return sum;
    },
    // 合约员
    async getParameterTitle() {
      this.ContractorQuery.curItemId =
        window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "/contractor/getParameterTitle",
        {
          params: this.ContractorQuery,
        }
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      console.log(res.data, 133);
      this.ContractorData = res.data;
    },
    ContractorReport(item) {
      this.ContractorVisible = true;
      this.curContractor.curItemId = this.ContractorQuery.curItemId;
      this.curContractor.project = item.project;
      this.curContractor.total = item.total;
      this.curContractor.temporaryTotal = item.temporaryTotal;
      this.curContractor.part_project = item.part_project;
    },
    ContractorClosed() {
      this.$refs.ContractorDialog.resetFields();
    },
    async submitContractor() {
      let formData = new FormData();
      formData.append("curItemId", this.ContractorQuery.curItemId);
      formData.append("projectName", this.curContractor.project);
      formData.append("total", this.curContractor.total);
      formData.append("temporaryTotal", this.curContractor.temporaryTotal);
      formData.append("partProjectName", this.curContractor.part_project);
      const { data: res } = await this.$http.post(
        "/contractor/reportParameterExcel",
        formData
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.getParameterTitle();
      this.ContractorVisible = false;
      
    },
    //获取工程名称及实体名称

    // 进度表
    async getItemList() {
      // this.proQuery.curItemId = window.sessionStorage.getItem("activeItem");
      // const { data: res } = await this.$http.get("");
      // this.itemlist = res.data;
    },
    async getProgress() {
      this.proQuery.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "/progresser/getReportProgress",
        {
          params: this.proQuery,
        }
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      console.log(res);
      this.progress = res.data.list;
      this.proTotal = res.data.total;
    },
    //计划表
    async getPlan() {
      //todo
    },
    proSizeChange(newSize) {
      this.proQuery.pagesize = newSize;
      this.getProgress();
    },
    proCurChange(newPage) {
      this.proQuery.pagenum = newPage;
      this.getProgress();
    },
    reportPro(item) {
      this.reportProVisible = true;
      this.curPro.progressContentId = item.id;
      this.curPro.progressNo = item.progressNo;
      this.curPro.itemNo = item.itemNo;
      this.curPro.itemName = item.itemName;
      this.curPro.beginPile = item.beginPile;
      this.curPro.endPile = item.endPile;
      this.curPro.completeQuantity = item.completeQuantity;
    },
    reportProClosed() {
      this.$refs.reportPro.resetFields();
    },
    async submitPro() {
      let formData = new FormData();
      formData.append("curItemId", window.sessionStorage.getItem("activeItem"));
      formData.append("itemNo", this.curPro.itemNo);
      formData.append("beginPile ", this.curPro.beginPile);
      formData.append("endPile", this.curPro.endPile);
      formData.append("completeQuantity", this.curPro.completeQuantity);
      const { data: res } = await this.$http.post(
        "progresser/reportProgress",
        formData
      );
      console.log(res, "施工员");
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      console.log("sadsa");
      this.reportProVisible = false;
    },

    // 试验
    async getExperiment() {
      this.expQuery.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "experimenter/getReportExperiment",
        { params: this.expQuery }
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.experiment = res.data.list;
      // console.log(this.experiment)
      this.expTotal = res.data.total;
      const { data: result } = await this.$http.get(
        "/experimenter/getProjectAndMaterial",
        { params: this.expQuery }
      );
      if (result.code !== 200) return this.$message.error(res.msg);
      this.materialList = result.data.material;
      this.projectList = result.data.project;
    },
    expSizeChange(newSize) {
      this.expQuery.pagesize = newSize;
      this.getExperiment();
    },
    expCurChange(newPage) {
      this.expQuery.pagenum = newPage;
      this.getExperiment();
    },
    reportExp(item) {
      this.reportExpVisible = true;
      this.curExp.id=item.id;
      this.curExp.listQuantity = item.listQuantity;
      this.curExp.progressQuantity = item.progressQuantity;
      this.curExp.actualRequiredQuantity = item.actualRequiredQuantity;
      this.curExp.obtainedQuantity = item.obtainedQuantity;
      this.curExp.passQuantity = item.passQuantity;
      // if(item.testItem != null){ this.curExp.itemName = item.testItem; }
      // else{ this.curExp.itemName = "无" }
      // this.curExp.inventoryQuantity = item.listQuantity;
      // this.curExp.formula = item.formula;
      // // 公式计算得到应作量，或固定值
      // this.curExp.requiredQuantity = parseInt(item.formula);
    },
    reportExpClosed() {
      this.$refs.reportExp.resetFields();
    },
    async submitExp() {
      let formData = new FormData();
      formData.append("experimentContentId",this.curExp.id);
      formData.append("listQuantity", this.curExp.listQuantity);
      formData.append("progressQuantity", this.curExp.progressQuantity);
      formData.append(
        "actualRequiredQuantity",
        this.curExp.actualRequiredQuantity
      );
      formData.append("obtainedQuantity", this.curExp.obtainedQuantity);
      formData.append("passQuantity", this.curExp.passQuantity);
      const { data: res } = await this.$http.post(
        "experimenter/reportExperiment",
        formData
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      console.log(res);
      this.$message.success(res.msg);
      this.reportExpVisible = false;
    },

    // 资料
    async getDocument() {
      this.docQuery.curItemId = window.sessionStorage.getItem("activeItem");
      const { data: res } = await this.$http.get(
        "documenter/getReportDocument",
        {
          params: this.docQuery,
        }
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.document = res.data.content.list;
      console.log("hello", res);
      console.log("nihao", this.document);
      this.docTotal = res.data.content.total;
      this.docSum = res.data.sum;
      console.log("333", this.docSum);
    },
    planchange(newPage) {
      this.planQuery.pagenum = newPage;
      this.getPlan();
    },
    planSizechange(newSize) {
      this.planQuery.pagesize = newSize;
      this.getPlan();
    },
    docSizeChange(newSize) {
      this.docQuery.pagesize = newSize;
      this.getDocument();
    },
    docCurChange(newPage) {
      this.docQuery.pagenum = newPage;
      this.getDocument();
    },
    reportDoc(item) {
      this.reportDocVisible = true;
      this.curDoc.documentId = item.id;
      this.curDoc.volume = item.volume;
      this.curDoc.documentContentName = item.documentContentName;
      this.curDoc.documentDescription = item.documentDescription;
      this.curDoc.documentCompleteDescription =
        item.documentCompleteDescription;
      this.curDoc.totalNum = item.totalNum;
      this.curDoc.organizationNum = item.organizationNum;
      this.curDoc.supervisorNum = item.supervisorNum;
      this.curDoc.designNum = item.designNum == -1 ? "不需要" : item.designNum;
      this.curDoc.constructionNum = item.constructionNum;
      this.curDoc.archiveOriginalNum = item.archiveOriginalNum;
      this.curDoc.archiveCopyNum = item.archiveCopyNum;
    },
    reportDocClosed() {
      this.$refs.reportDoc.resetFields();
    },
    async submitDoc() {
      let formData = new FormData();
      this.curDoc.designNum =
        this.curDoc.designNum == "不需要" ? -1 : this.curDoc.designNum;
      this.curDoc.constructionNumNum =
        this.curDoc.constructionNum == "不需要"
          ? -1
          : this.curDoc.constructionNum;
      formData.append("documentContentId", this.curDoc.documentId);
      formData.append("volume", this.curDoc.volume);
      formData.append("documentContentName", this.curDoc.documentContentName);
      formData.append("documentDescription", this.curDoc.documentDescription);
      formData.append(
        "documentCompleteDescription",
        this.curDoc.documentCompleteDescription
      );
      formData.append("totalNum", this.curDoc.totalNum);
      formData.append("organizationNum", this.curDoc.organizationNum);
      formData.append("supervisorNum", this.curDoc.supervisorNum);
      formData.append("designNum", this.curDoc.designNum);
      formData.append("constructionNum", this.curDoc.constructionNumNum);
      formData.append("archiveOriginalNum", this.curDoc.archiveOriginalNum);
      formData.append("archiveCopyNum", this.curDoc.archiveCopyNum);
      console.log(this.curDoc);
      const { data: res } = await this.$http.post(
        "documenter/reportDocument",
        formData
      );
      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.reportDocVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;
  color: #666;
  display: block;
  padding: 10px 0 0 2px;
}
</style>
